import React from "react";
import Navbar from "../../CommonCompounds/Nav/Navbar";
import Banner from "../../CommonCompounds/Banner/Banner";
import FiveColumnGrid from "../../CommonCompounds/Progress.jsx/FiveColumnGrid";


function HomePAGE (){
    return(

      <>
      <Navbar />
      <Banner />
      <FiveColumnGrid />
    

      </>
     
    );
}
export default HomePAGE;