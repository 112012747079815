import React from "react";
import Navbar from "../../CommonCompounds/Nav/Navbar";
import Banner from "../../CommonCompounds/Banner/Banner";
import Processing from "../../CommonCompounds/Processing/Processing"
import From from "../../CommonCompounds/FromPage/FromPage";


function GuestInformation (){
    return(

      <>
      <Navbar />
      <Banner />
      <Processing />
      <From />

  
    

      </>
     
    );
}
export default GuestInformation;