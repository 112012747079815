import Image1 from '../Asstes/Images/Logo.svg';
import Image2 from '../Asstes/Images/banner.svg'
// import Image8 from '../Assests/Images/Vector (2).svg'
import Image5 from '../Asstes/Images/akar-icons_edit.png'
import Image6 from '../Asstes/Images/mdi_filter.png';
import Image7 from '../Asstes/Images/Frame 29.svg';
import Image4 from '../Asstes/Images/material-symbols_info.svg'
import Image8 from '../Asstes/Images/material-symbols_call.svg';
import Image9 from '../Asstes/Images/material-symbols_mail.svg';
import Image10 from "../Asstes/Images/Frame 1113 (3).svg";
import Image11 from "../Asstes/Images/OBJECTS.svg";
import Image12 from '../Asstes/Images/Deluxe Room Only.svg';
import Image13 from '../Asstes/Images/carbon_close-filled.svg';



 
const Images = {
  rost: Image1,
  Banner: Image2,
  rooms: Image4,
  edit: Image5,
  filter:Image6,
  Rooms:Image7,
  Call:Image8,
  mail:Image9,
  Logo:Image10,
  Loginbanner:Image11,
  DeluxeRoom:Image12,
  carbon_close:Image13,
//   ex:Image8,
  

  
}

export default Images;
