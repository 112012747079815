import React from "react";
import Login from "../../CommonCompounds/Login/Login";


function LoginPage() {
    return(
        <>
          <Login />
        </>
    );
    
}

export default LoginPage;