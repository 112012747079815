import React from "react";
import  Navbar from "../../CommonCompounds/Nav/Navbar";
import Banner from "../../CommonCompounds/Banner/Banner";
import Game from '../../CommonCompounds/Game/Games'



function Exendyourpage (){
return(
    <>
   <Navbar />
    <Banner />
     <Game />
    </>
);
}
export default Exendyourpage;